.App {
  text-align: center;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
}
.sendbutton {
  width: 150px;
  height: 50px;
}
